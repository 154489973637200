<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Suppose a water heat with a
        <number-value :value="volCylinder" unit="\text{L}" />
        cylinder of propane gas,
        <chemical-latex content="C3H8," />
        at
        <stemble-latex content="$25.0\,^\circ\text{C}$" /> and a pressure of
        <number-value :value="PcylInit" unit="\text{atm}" />
        is allowed to run until the pressure of the propane in the tank reaches
        <number-value :value="PcylFinal" unit="\text{atm.}" />
        The heat produced from the combustion of the released propane is used to raise the
        temperature of
        <number-value :value="volWater" unit="\text{L}" />
        of water from
        <stemble-latex content="$25.0\,^\circ\text{C}$" />
        to
        <number-value :value="finalTemp" unit="^\circ\text{C.}" />
        Note that the heat capacity of water is
        <stemble-latex content="$4.184\,\text{J}\,\text{g}^{-1}\,^\circ\text{C}^{-1}$" />
        and the density of water is
        <stemble-latex content="$1.00\,\text{kg/L.}$" />
      </p>

      <p class="mb-2">
        a) Calculate the heat (in kJ) that was actually <b>absorbed by the water</b>.
      </p>

      <calculation-input
        v-model="inputs.heatAbsorbed"
        class="mb-5"
        prepend-text="$\text{q}_\text{soln}:$"
        append-text="$\text{kJ}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) How many moles of propane were released from the tank at
        <stemble-latex content="$25.0^\circ\text{C.}$" />
      </p>

      <calculation-input
        v-model="inputs.molPropane"
        class="mb-5"
        prepend-text="$\text{Amount}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) Write the balanced chemical equation for the combustion of propane under standard
        conditions.
      </p>

      <chemical-notation-input
        v-model="inputs.combustionEqn"
        dense
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        d) Using the data provided
        <a
          href="https://openstax.org/books/chemistry-2e/pages/g-standard-thermodynamic-properties-for-selected-substances"
        >
          here</a
        >, calculate the heat produced by the combustion of the propane released from the tank at
        <stemble-latex content="$25.0^\circ\text{C.}$" />
      </p>

      <calculation-input
        v-model="inputs.heatProduced"
        class="mb-5"
        prepend-text="$\text{q}_\text{rxn}:$"
        append-text="$\text{kJ}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        e) What percentage of the heat from the combustion reaction was transferred to the water?
        This gives us a measure of the efficiency of the water heater.
      </p>

      <calculation-input
        v-model="inputs.pctAbsorbed"
        class="mb-0"
        prepend-text="$\text{Efficiency}:$"
        append-text="$\%$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalReactionInput from '@/tasks/components/inputs/ChemicalReactionInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'Question459',
  components: {
    ChemicalNotationInput,
    LatexNumber,
    NumberValue,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        heatAbsorbed: null,
        molPropane: null,
        combustionEqn: null,
        heatProduced: null,
        pctAbsorbed: null,
      },
    };
  },
  computed: {
    volCylinder() {
      return this.taskState.getValueBySymbol('volCylinder').content;
    },
    PcylInit() {
      return this.taskState.getValueBySymbol('PcylInit').content;
    },
    PcylFinal() {
      return this.taskState.getValueBySymbol('PcylFinal').content;
    },
    volWater() {
      return this.taskState.getValueBySymbol('volWater').content;
    },
    finalTemp() {
      return this.taskState.getValueBySymbol('finalTemp').content;
    },
  },
};
</script>
